@import "./variables.scss";
$chosen-highlight-color: #b9ecff;
html {
  overflow-y: hidden;
}

body {
  font-weight: normal !important;
  overflow-y: scroll;
}

html, body {
  height: 100%;
  > div[ui-view] {
    height: 100%;
    width: 100%;
  }
}

#page-content {
  position: relative;
  padding-top: 0;
  height: 100%;

  & > .content {
    margin-left: $sidebar-width;

    &.hide-sidebar {
      margin-left: 0;
    }
  }

  &.hide-submenu {
    padding-top: $page-content-padding;

    #sidebar ul.sidebar-nav {
      margin-top: 6px;
    }
  }
}

.chosen-container {
  width: 100% !important;
  .chosen-results li.highlighted {
    background-color: $chosen-highlight-color;
    background-image: none;
  }
}

.modal {
  z-index: 999999 !important;
}

.modal-backdrop{
  z-index: 999998 !important;
}

.ui-notification {
  z-index: 9999999;
}