.borderless {
  border: none;
  &.table {
    th, td {
      border-color: transparent;
      border-width: 0;
    }
  }
}

.remove-background {
  background-color: transparent;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.vtop {
  display: inline-block;
  vertical-align: top;
  float: none;
}

.page-details {
  background: url("#{$image-path}/table/page_details.png");
  background-position: center center;
  display: inline-block;
  height: 23px;
  width: 23px;
  background-repeat: no-repeat;
}

.show-on-page {
  background: url("#{$image-path}/table/show_on_page.png");
  background-position: center center;
  display: inline-block;
  width: 23px;
  height: 19px;
  background-repeat: no-repeat;
}

.col-middle {
  vertical-align: middle;
}

.col-top {
  vertical-align: top;
}

.position-relative {
    position: relative;
}

.position-b-r {
    position: absolute;
    bottom: 0;
    right: 0;
}

[placeholder] {
  text-overflow: ellipsis;
}
