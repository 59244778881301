@import '@monsido/colors/dist/colors.scss';

.nav-tabs {
  border-bottom: 0;
  > li.active {
    padding: 10px 14px;
    > a {
      display:inline-block;
      padding: 1px 2px 3px 2px;
      border-width: 0 0 2px 0;
      border-color: $main-1;
      color: $main-1;

      &:hover, &:focus {
        color: $main-1;
        border-width: 0 0 2px 0;
        border-color: $main-1;
      }
    }
  }
}
