@import '@monsido/colors/dist/colors.scss';

$submenu-color: $main-1;
$menu-navbar-height: 50px;

#submenu.navbar.navbar-inverse {
    margin-top: $menu-navbar-height;
    color: $submenu-color;
    background: #FFF;
    border: none;
    height: $menu-navbar-height;
    box-shadow: 0 3px 4px -2px #C6C9CA;
    padding-bottom: 53px;
    border-radius: 0;
    margin-bottom: 0;

    z-index: 1029;

    .fa,
    a {
        color: $submenu-color;
    }

    .fa span {
        font-family: "Roboto", "Arial Narrow", sans-serif;
        font-size: 0.85em;
    }

    .active {
        border-bottom: 2px solid $submenu-color;
    }

    .notification-circle {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #FF5A07;
        position: absolute;
        left: 30px;
        top: 14px;
    }

    .container-fluid {
        text-align: center;

        .navbar-nav {
            display: inline-block;
            float: none;
        }
    }
}
