@import 'variables.scss';

.selector-container.multiple {
  .selector-values {
    & > li {
      background-color: rgb(67, 133, 245);
      font-weight: 400;
      color: rgb(255, 255, 255);
      border-radius: 20px;
      padding: 5px 10px;
      box-shadow: none;
      text-shadow: none;
      line-height: 1.2em;
    }
  }
}
ngx-select{
    .ngx-select__selected-plural{
        background-color: rgb(67, 133, 245);
        font-weight: 400;
        color: rgb(255, 255, 255);
        border-radius: 20px;
        padding: 5px 10px;
        box-shadow: none;
        text-shadow: none;
        line-height: 1.2em;
        border-color: transparent;
        & > a {
            opacity: 1;
            color: $bg-white;
        }
    }

    .dropdown-menu > li > a {
        &.ngx-select__item {
            color: $select-blue;
        }

      &.ngx-select__item_active {
          color: $bg-white;
          background-color: $select-blue;
      }
    }
    .ngx-select__clear-icon {
        color: $grey-text-ada;
    }
    .ngx-select__toggle-buttons {
        a.ngx-select__clear {
            margin-right: -5px;
            & ~ i {
                display: none;
            }
        }
    }
}
