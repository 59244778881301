@import '../deprecate/frontend-components/src/styles/variables.scss';
@import '@monsido/colors/dist/colors.scss';

/** Local styles **/

/** Font size **/
$font-size-base: 12px;

/** **/
$partner-color-base: #21a0f9;
$customer-color-base: #57b2b7;

/** Top menu **/
$topmenu-navbar-height: 60px;

/** Colors **/
$monsido-green: #57b2b7;
$light-green: #86d08a;
$light-black: #505050;
$light-grey: #eaeaeb;
$light-red: #e66468;
$icon-grey: #6a6a6a;

/** Text **/
$grey-text: #a6a6a6;
$dark-grey: #8d8d8d;
$grey-text-ada: #595959;
$text-warning: #f0ad4e;
$text-gray-small: #777777;

$bg-white: #FFF;

$select-blue: #255783;
$legacy-main-color: $main-1;
$legacy-hover-color: #0574c9;

$primary-legacy-bg-color: #286090;
$primary-legacy-bg-color-hover: #286090;
$primary-legacy-border-color: #2e6da4;
$primary-legacy-border-color-hover: #204d74;
$primary-legacy-border-color-focus: #122b40;
