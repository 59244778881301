@import '@monsido/colors/dist/colors.scss';

/**
 * Bootstrap overrides
 */

/**
 * Columns
 */

$grid-columns: 48;

/**
 * Typography
 */
$font-family-sans-serif: 'Roboto','Arial Narrow',sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base :12px;


/**
 * Scaffolding
 */
$body-bg: #f3f5f7;
$link-color: $main-1;
$text-color: #555557;

/**
 * Panels
 */
$panel-default-border: #e6e6e6;

/**
* Buttons
*/
//$btn-secondary-bg: #fff;
$btn-secondary-color: $main-1;
$btn-secondary-border: #e4e4e4;

/**
* Labels
*/
$state-success-bg:               #dff0d8 !default;
$state-info-bg:                  #d9edf7 !default;
$state-warning-bg:               #fcf8e3 !default;
$state-danger-bg:                #f2dede !default;

$label-success-bg: $state-success-bg;
$label-info-bg: $state-info-bg;
$label-warning-bg: $state-warning-bg;
$label-danger-bg: $state-danger-bg;

/**
* Brands
*/

//$brand-primary: none;


/**
* Dropdown
*/
$dropdown-link-color: $btn-secondary-color;
$dropdown-link-hover-color: $btn-secondary-color;
//$dropdown-link-hover-bg: transparent;


$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;

$gray-lighter: #eee;
$link-hover-color: rgb(5, 116, 201);
$gray-dark: #333;
