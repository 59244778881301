.table-filters-selector {
  position: relative;

  label.input {
    display: block;
    width: 100%;
    min-height: 40px;
    margin-bottom: -10px;

    ul {
      margin-bottom: 0;
      min-height: 30px;
      li {
        background-color: #4385f5;
        border-radius: 20px;
        padding: 5px 10px;
        color: #ffffff !important; //Important due to ngDialog
        margin-left: 10px;
        font-weight: normal;
      }
    }
  }

  .label-list {
    width: calc(100% - 30px);
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  .table-filters-selector-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .selected-filters-container {
    cursor: pointer;
    min-height: 1em;
  }

  div.tfs-dropdown-menu {
    position: absolute;
    top: 21px;
    z-index: 1000;
    width: 320px;
    background-color: #fff;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.18);
    border: 0;
    padding-left: 0;
    float: left;
    margin: 0;
    ul:first-child {
      li {
        position: relative;
      }
    }

    ul {
      li {
        padding: 10px 15px;
        &.selected {
          background-color: #f5f5f5;
          color: #424242;
        }

        &:hover, &.selected:hover {
          background-color: #e3e3e3;
        }

        &.group {
          &:hover {
            background-color: inherit;
          }
          color: #595959;
          font-size: 0.9em;
          line-height: 1.6em;
          border-bottom: 1px solid #e3e3e3;
        }

        &:not(.group) {
          line-height: 2em;
        }

        .dropdown-close {
          position: absolute;
          top: 16px;
          right: 15px;
        }

      }

      &.list-unstyled {
        margin-bottom: 0;
      }
    }

    hr {
      margin: 10px 0;
    }

    .tfs-submenu {
      .tfs-dropdown-menu  {
        top: 0;
        left: 100%;
        margin-top: -1px;

        li {
          color: #555557;
        }

        &.items {
          li {
            position: relative;
            padding: 10px 12px;

            &.label-header {
              background-color: #0074e6;
              color: #FFFFFF;
              font-size: 1.1em;
              i {
                font-size: 1.3em;
              }

              i:first-child {
                padding-right: 10px;
              }
            }

            &.label-search-box {
              position: relative;
              background: #FFFFFF;
              border-width: 1px;
              border-style: solid;
              border-color: rgba(0, 0, 0, 0.08);
              border-left: none;
              border-right: none;
              padding-left: 40px;

              input {
                width: 90%;
                background-color: white;
                padding: 0 .4em;
                line-height: 1.5em;
                border: 0px solid #ccc;
              }

              i {
                position: absolute;
                font-size: 1.2em;
                line-height: 1.6em;
                left: 10px;
              }
            }

            &.label-item:hover {
              background: #f5f5f5;
              color: #424242;
              cursor: pointer;

              .indicators.active {
                .remove {
                  display: initial;
                }
              }
            }
          }

          ul.options {
            max-height: 300px;
            overflow-y: auto;
          }

          .indicators {
            opacity: 0;
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 15px;

            .remove {
              display: none;
            }

            &.active {
              opacity: 1;
            }
          }

          .label-color-box {
            width: 15px;
            height: 15px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 0.2em;
          }

          .label-name {
            display: inline-block;
            padding-left: 7px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
