@import "../../../../src/styles/variables.scss";
@import "mixins.scss";

$border-radius: 3px;

.search-input {
  margin-bottom: 0;

  .input-group {
    .input-group-addon {
      padding-top: 5px;

      border-bottom-left-radius: $border-radius;
      border-top-left-radius: $border-radius;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    input.form-control[type="search"] {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;

      height: 40px;
    }
  }
  @include table-search-variant(#FFF, #e6e6e6, #7B7B7B, #7B7B7B); // Default ...

  &.white-theme {
    @include table-search-variant(#FFF, #e6e6e6, #7B7B7B, #7B7B7B);
  }

  &.grey-theme {
    @include table-search-variant(#EBEBEC, transparent, #7B7B7B, #7B7B7B);
  }
}


