@import "bootstrap-variables.scss";

.table-filters {
  margin:0;
  list-style:none;
  display:-moz-flex;
  display:flex;
  justify-content:space-around;
  flex-flow:row wrap;
  align-items:stretch;
  border-top: 0 !important;
  padding-bottom: 10px !important;

  .left-part-wrapper {
    flex: 1;
  }

  .selector-input {
    border: none;
    box-shadow: none !important;
  }

  & > p {
    margin: 0 15px 0 0;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }

  & > p i {
    font-size: 1.3em
  }

  .table-filters-selector-container {
    flex: 1 0 auto;
    max-width: calc(100% - 100px);

    .selector-dropdown {
      border-top: 1px solid #ccc;
    }
  }

  .clear {
    cursor: pointer;
  }

  .table-filters-clear {
    cursor: pointer;
  }

  &.with-border-b {
    border-bottom: 1px solid $panel-default-border;
  }
}
