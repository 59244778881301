@import 'bootstrap-variables.scss';
@import 'mixins.scss';
@import '@monsido/colors/dist/colors.scss';

$btn-border-radius: 3px;

.btn-marine-blue {
    @include button-variant($btn-secondary-color, transparent, #fff, #0682e2, transparent, #fff);
}

.btn {

    padding: 6px 12px;

    &.btn-flat {
        @include set-border-radius(0);
    }
}

.btn:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
}

.btn:focus-visible {
    outline: 5px auto $secondary-14;
    outline-offset: -1px;
    box-shadow: 0 0 0 1px $secondary-14;
}

button:focus:not(:focus-visible) + ngb-tooltip-window {
    display: none !important;
}

.btn-border-radius {
    border-radius: $btn-border-radius;
}

.btn.btn-default,
.btn.btn-light,
.btn-secondary {
    color: $main-1;
    background-color: #fff;
    border-color: #e4e4e4;
}

.btn-primary {
    color: #fff;
    background-color: $primary-legacy-bg-color !important;
    border-color: $primary-legacy-border-color !important;
}

.btn-primary:hover {
    background-color: $primary-legacy-bg-color-hover !important;
    border-color: $primary-legacy-border-color-hover !important;
}

.btn-primary.focus, .btn-primary:focus {
    background-color: $primary-legacy-bg-color-hover !important;
    border-color: $primary-legacy-border-color-focus !important;
}

.btn-primary:focus,
.btn-primary:hover {
    background-color: $legacy-main-color;
}

.btn-secondary:hover {
    color: $main-1;
    background-color: #e6e6e6;
    border-color: #c5c5c5;
}

.btn-secondary:active,
.btn-secondary.active {
    color: $main-1;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #c5c5c5;
}

.btn-secondary:focus {
    color: $main-1;
    background-color: #e6e6e6;
    border-color: #a4a4a4;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
    text-decoration: none;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn, .btn-secondary:disabled, .btn-secondary.disabled {
    color: $main-1;
    background-color: #fff;
    border-color: #e4e4e4;
    cursor: not-allowed;
    opacity: .65;
    box-shadow: none;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    appearance: none;
    margin-top: -5px !important;
}

.close {
    float: right;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    filter: alpha(opacity=20);
}