@import "variables.scss";

.border-bottom {
  border-bottom: 1px solid #eee;
}

.border-transparent {
  border-color: transparent !important;
}

.border {
  border-width:1px;
  border-style: solid;
}

