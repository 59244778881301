@font-face {
    font-family: "summernote";
    src: url("/assets/fonts/summernote.eot?e557617934c52ea068954af79ed7c221");
    src: url("/assets/fonts/summernote.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/summernote.woff?e557617934c52ea068954af79ed7c221") format("woff"),
    url("/assets/fonts/summernote.ttf?e557617934c52ea068954af79ed7c221") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.note-editor {
    .modal.note-modal.show {
        z-index: 2147483647 !important;

        .modal-dialog {
            background-color: #fff;

            .modal-content {
                transform: none;

                .modal-header {
                    .modal-title {
                        float: left;
                    }
                    .btn-close {
                        float: right;
                    }
                }

                .modal-body {
                    .form-group {
                        margin-left: 0;
                    }
                }
            }

        }

    }
}

body.summernote-on-page {
    .modal-backdrop.show {
        display: none;
    }
}
