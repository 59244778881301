.mon-disabled {
  color: $grey-text !important;
  &:hover {
    cursor: not-allowed !important;
  }
  * {
    color: $grey-text !important;
  }
}

.text-right {
  text-align: right;
}