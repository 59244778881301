$radius: 50%;
$color-green: #7EC97F;

.policy-target {
  text-align: center;

  .passed {
    border-color: $color-green;
    background-color: $color-green;
    color: #FFF;
  }
}