.select-grey {
    .ngx-select__toggle {
        display: block;
        margin: 0;
        position: relative;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
        border: 1px solid #bbb;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 hsla(0, 0%, 100%, 0.8);
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: inherit;
        background-color: #f9f9f9;
        background-image: linear-gradient(#fafafa, #eee);
    }
    .ngx-select_multiple {
        width: 100%;
    }
}

.flex-label {
    display: flex;
    margin-left: 10px;
    label {
        width: 10%;
        margin-right: 2%;
        text-align: right;
    }
    .selector-input {
        width: 100%;
    }
}

._ml-15 {
    margin-left: -15px;
}

mon-form-field-errors .invalid-feedback,
mon-form-field-errors-ac .invalid-feedback{
    margin-top: 0;
    margin-bottom: 10px;
}

.mon-form-field-inline-container {
    display: flex;
    align-items: start;
    label {
        text-transform: capitalize;
    }
    > label + * {
        flex: 1;
        .form-group {
            margin: 0;
        }
    }
}
