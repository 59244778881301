.table-container {
    min-height: 200px;
    ul.pagination {
        margin: 0 0 0 20px;
        li {
            a {
                cursor: pointer;
            }
        }
    }

    .pagination-wrapper {
        display: inline-block;
    }

    .pagination-info {
        padding: 8px 0;
    }

    .table-spinner{
        text-align: center;
        padding: 70px 0;
    }

    .no-result-message-container {
        p {
            font-size: 21px;
        }
    }
}
