@import "../../../src/styles/bootstrap-variables.scss";

.nav.nav-tabs.table-nav-tabs {
    border-bottom: none;

    &>li {
        cursor: pointer;

        &:hover {
            background-color: $gray-lighter;

            a {
                color: $link-hover-color;
            }
        }

        &>a {
            padding: 1px 2px 3px;
            line-height: 1.2em;
            border-width: 0;
            border-bottom-width: 2px;
            border-radius: 0;
            margin: 10px 14px;

            .icon {
                font-size: 1.5em;
                padding-right: 10px;
            }

            &:hover {
                background-color: inherit;
                border-bottom-color: transparent;
            }
        }

        &.active {
            padding: 0;
        }

        &.active>a,
        &.active>a:hover,
        &.active>a:focus {
            border-width: 0;
            border-bottom-width: 2px;
            border-color: $link-color;
            border-style: solid;
            color: $link-color;
            cursor: pointer;
            background-color: transparent;
        }
    }

    .open>a {
        background-color: transparent;
        border-bottom-width: 2px;
        border-color: transparent;
    }

    .dropdown-menu>.active>a,
    .dropdown-menu>.active>a:hover,
    .dropdown-menu>.active>a:focus {
        color: #fff;
    }

}

.filters-button {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding: 0 0.75em;
    cursor: pointer;
}