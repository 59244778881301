@mixin set-border-radius($radius) {
  border-radius: $radius;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: "alpha(opacity=#{$opacity-ie})";
}

@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
  border-color: $border;

  & > .card-header {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;

    + .panel-collapse > .card-body {
      border-top-color: $border;
    }
    .badge {
      color: $heading-bg-color;
      background-color: $heading-text-color;
    }
  }
  & > .card-footer {
    + .panel-collapse > .card-body {
      border-bottom-color: $border;
    }
  }
}

@mixin label-variant($color) {
  background-color: $color;

  &[href] {
    &:hover,
    &:focus {
      background-color: darken($color, 10%);
    }
  }
}