mon-form-field-dynamic-ac.field-with-label {
    --mon-form-field-dynamic-input-display: inline-block;
    --mon-form-field-dynamic-input-width: calc(100% - 80px);

    .offset-sm-8 {
        margin-left: 16.666%;
    }
}

.mon-dynamic-label-wrapper {
    box-sizing: border-box;
    display: inline-flex;
    justify-content: end;

    padding: 7px 2px 0 5px;
    min-width: 80px;
    margin-right: auto;

    .label {
        display: inline-flex;
        height: 15px;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;

        &.label-info {
            background-color: #d9edf7;
            color: #31708f;
            font-size: 75%;
            font-weight: 700;
            padding: 0 0 0 5px;
        }
    }

    .mon-dynamic-label-delete-button {
        box-sizing: border-box;
        display: inline-flex;
        font-size: 9px;
        height: 15px;
        appearance: none;
        border: none;
        background-color: #d9edf7;
        color: #31708f;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 1px $secondary-14;
        }
    }
}