@import '../deprecate/frontend-components/src/styles/app.scss';
@import 'bootstrap-variables.scss';
@import 'variables.scss';
@import 'accordion.scss';
@import 'buttons.scss';
@import 'background.scss';
@import 'modal.scss';
@import 'panels.scss';
@import 'select.scss';
@import 'selector.scss';
@import 'stacked-icon.scss';
@import 'labels.scss';
@import 'summernote.scss';
@import 'cursor.scss';
@import 'display.scss';
@import 'text.scss';
@import 'show-tooltip.scss';
@import 'forms.scss';
@import 'pagination.scss';
@import 'from-bootstrap-3.scss';
@import 'bootstrap-3-grid.scss';
@import 'dynamic-component.scss';
@import 'global-overrides.scss';
@import 'snake-spinner';
@import '../ng2/services/loading/services/loading.service.scss';

html {
    font-size: 12px;
}

body {
    font-family: 'Roboto', 'Arial Narrow', sans-serif;
    font-size: 12px;
    line-height: 1.42857143;
    color: $secondary-4;
    background-color: $secondary-1;
}

a:not([href]):not([class]),
a,
.btn-link,
a.btn,
.nav-link {
    color: $legacy-main-color;
    text-decoration: none;
}

.nav-link:focus,
.nav-link:hover {
    color: $legacy-hover-color;
    text-decoration: none;
    background-color: #eee;
    border-color: #eee #eee #ddd;
}

a:not([href]):not([class]):hover,
a:focus,
a:hover {
    color: $legacy-hover-color;
    text-decoration: none;
}

input[type=search] {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button.no-button-appearance {
    all: unset;
}

.panel,
.card {
    margin-bottom: 17px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.card-body {
    padding: 15px;
}

.monsido-panel.card .card-body,
.panel-monsido-card .card-body {
    padding-top: 30px;
    padding-bottom: 30px;
}

.monsido-panel.card,
.panel-monsido-card {
    border-color: transparent;
    border-radius: 2px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.table {
    margin-bottom: 17px;
}

.table> :not(caption)>*>th,
.table> :not(caption)>*>td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-bottom-width: 0;
    display: table-cell;
    float: none;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

.table> :not(caption)>*>td {
    border-top: 1px solid #ddd;
}

/* e.g. TBODY */
.table> :not(:first-child) {
    border-top: none;
}

.input-group-prepend {
    padding: 0 !important;
}

.input-group-text {
    padding-right: 0;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 12px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.dropdown-menu>li>a:not([href]):not([class]),
.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: $legacy-main-color;
    white-space: nowrap;
}

.dropdown-menu>li>a:not([href]):not([class]):hover,
.dropdown-menu>li>a:not([href]):not([class]):focus,
.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: $legacy-main-color;
    text-decoration: none;
    background-color: #f5f5f5;
}

.open>.dropdown-menu {
    display: block;
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.dropdown-item {
    padding-left: 0;
    padding-right: 0;
}

.mon-panel-header.card-header,
.card.monsido-panel>.card-header {
    color: #333;
    background-color: #fff;
    border-color: #e6e6e6;
}

.card-header {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.form-control {
    width: 100%;
    height: 31px;
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control,
output {
    display: block;
    font-size: 12px;
    line-height: 1.42857143;
    color: #555;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.form-group {
    margin-bottom: 15px;
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " ";
}

.btn-group-vertical>.btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
    clear: both;
}

.form-horizontal .control-label,
.form-horizontal .col-form-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
}

.selector-container.multiple.remove-button .selector-helper {
    padding-right: 15px;
}

label.separator {
    margin-bottom: 4px !important;
}

input+.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #959597;
}

.outer-l-none {
    margin-left: 0 !important;
}

.inner-l-none {
    padding-left: 0 !important;
}

.inner-t-mini {
    padding-top: 5px !important;
}

.outer-b-none {
    margin-bottom: 0 !important;
}

.card-body .form-group {
    margin-left: -15px;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
    margin-top: 8.5px;
    margin-bottom: 8.5px;
}

.modal-header {
    display: block;
}

.modal-title {
    margin-top: 0;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.mon-panel-header.card-header {
    margin-bottom: 10px;
}

.form-text {
    font-size: 12px;
}

textarea.form-control {
    height: auto;
}

.mon-dialog .mon-dialog-body-with-footer {
    background: #f3f5f7 !important;
}

.inner-none {
    padding: 0;
}

.btn-link:focus,
.btn-link:hover {
    text-decoration: underline;
}

.modal-header h4#modal-title {
    float: left;
}

.radio label {
    font-weight: 400;
}

.mon-dialog-close {
    color: $secondary-4;
}

.label-default {
    background-color: $text-gray-small;
    border-radius: 2px;
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    padding: 2px 5px;
}

.ngx-select_multiple input.form-control {
    width: 100% !important;
}

.hidden {
    display: none !important;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
    background-color: #337ab7;
}