@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-Thin.eot');
  src:  local('Roboto Thin'),
        local('Roboto-Thin'),
        url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/Roboto-Thin.woff') format('woff'),
        url('../fonts/Roboto-Thin.ttf') format('truetype'),
        url('../fonts/Roboto-Thin.svg#Roboto') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Thin';
  src:  url('../fonts/Roboto-Thin.eot');
  src:  local('Roboto Thin'),
        local('Roboto-Thin'),
        url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/Roboto-Thin.woff') format('woff'),
        url('../fonts/Roboto-Thin.ttf') format('truetype'),
        url('../fonts/Roboto-Thin.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-ThinItalic.eot');
  src:  local('Roboto ThinItalic'),
        local('Roboto-ThinItalic'),
        url('../fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/Roboto-ThinItalic.woff') format('woff'),
        url('../fonts/Roboto-ThinItalic.ttf') format('truetype'),
        url('../fonts/Roboto-ThinItalic.svg#Roboto') format('svg');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src:  url('../fonts/Roboto-ThinItalic.eot');
  src:  local('Roboto ThinItalic'),
        local('Roboto-ThinItalic'),
        url('../fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/Roboto-ThinItalic.woff') format('woff'),
        url('../fonts/Roboto-ThinItalic.ttf') format('truetype'),
        url('../fonts/Roboto-ThinItalic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-Light.eot');
  src:  local('Roboto Light'),
        local('Roboto-Light'),
        url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src:  url('../fonts/Roboto-Light.eot');
  src:  local('Roboto Light'),
        local('Roboto-Light'),
        url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-LightItalic.eot');
  src:  local('Roboto LightItalic'),
        local('Roboto-LightItalic'),
        url('../fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/Roboto-LightItalic.woff') format('woff'),
        url('../fonts/Roboto-LightItalic.ttf') format('truetype'),
        url('../fonts/Roboto-LightItalic.svg#Roboto') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src:  url('../fonts/Roboto-LightItalic.eot');
  src:  local('Roboto LightItalic'),
        local('Roboto-LightItalic'),
        url('../fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/Roboto-LightItalic.woff') format('woff'),
        url('../fonts/Roboto-LightItalic.ttf') format('truetype'),
        url('../fonts/Roboto-LightItalic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-Regular.eot');
  src:  local('Roboto Regular'),
        local('Roboto-Regular'),
        url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src:  url('../fonts/Roboto-Regular.eot');
  src:  local('Roboto Regular'),
        local('Roboto-Regular'),
        url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-RegularItalic.eot');
  src:  local('Roboto RegularItalic'),
        local('Roboto-RegularItalic'),
        url('../fonts/Roboto-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-RegularItalic.woff2') format('woff2'),
        url('../fonts/Roboto-RegularItalic.woff') format('woff'),
        url('../fonts/Roboto-RegularItalic.ttf') format('truetype'),
        url('../fonts/Roboto-RegularItalic.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-RegularItalic';
  src:  url('../fonts/Roboto-RegularItalic.eot');
  src:  local('Roboto RegularItalic'),
        local('Roboto-RegularItalic'),
        url('../fonts/Roboto-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-RegularItalic.woff2') format('woff2'),
        url('../fonts/Roboto-RegularItalic.woff') format('woff'),
        url('../fonts/Roboto-RegularItalic.ttf') format('truetype'),
        url('../fonts/Roboto-RegularItalic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-Medium.eot');
  src:  local('Roboto Medium'),
        local('Roboto-Medium'),
        url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/Roboto-Medium.svg#Roboto') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src:  url('../fonts/Roboto-Medium.eot');
  src:  local('Roboto Medium'),
        local('Roboto-Medium'),
        url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/Roboto-Medium.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-MediumItalic.eot');
  src:  local('Roboto MediumItalic'),
        local('Roboto-MediumItalic'),
        url('../fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/Roboto-MediumItalic.woff') format('woff'),
        url('../fonts/Roboto-MediumItalic.ttf') format('truetype'),
        url('../fonts/Roboto-MediumItalic.svg#Roboto') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src:  url('../fonts/Roboto-MediumItalic.eot');
  src:  local('Roboto MediumItalic'),
        local('Roboto-MediumItalic'),
        url('../fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/Roboto-MediumItalic.woff') format('woff'),
        url('../fonts/Roboto-MediumItalic.ttf') format('truetype'),
        url('../fonts/Roboto-MediumItalic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-Bold.eot');
  src:  local('Roboto Bold'),
        local('Roboto-Bold'),
        url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold.svg#Roboto') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src:  url('../fonts/Roboto-Bold.eot');
  src:  local('Roboto Bold'),
        local('Roboto-Bold'),
        url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-BoldItalic.eot');
  src:  local('Roboto BoldItalic'),
        local('Roboto-BoldItalic'),
        url('../fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BoldItalic.woff') format('woff'),
        url('../fonts/Roboto-BoldItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BoldItalic.svg#Roboto') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src:  url('../fonts/Roboto-BoldItalic.eot');
  src:  local('Roboto BoldItalic'),
        local('Roboto-BoldItalic'),
        url('../fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BoldItalic.woff') format('woff'),
        url('../fonts/Roboto-BoldItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BoldItalic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-Black.eot');
  src:  local('Roboto Black'),
        local('Roboto-Black'),
        url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype'),
        url('../fonts/Roboto-Black.svg#Roboto') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Black';
  src:  url('../fonts/Roboto-Black.eot');
  src:  local('Roboto Black'),
        local('Roboto-Black'),
        url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype'),
        url('../fonts/Roboto-Black.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src:  url('../fonts/Roboto-BlackItalic.eot');
  src:  local('Roboto BlackItalic'),
        local('Roboto-BlackItalic'),
        url('../fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BlackItalic.woff') format('woff'),
        url('../fonts/Roboto-BlackItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BlackItalic.svg#Roboto') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src:  url('../fonts/Roboto-BlackItalic.eot');
  src:  local('Roboto BlackItalic'),
        local('Roboto-BlackItalic'),
        url('../fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BlackItalic.woff') format('woff'),
        url('../fonts/Roboto-BlackItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BlackItalic.svg#Roboto') format('svg');
}
