@import '@monsido/colors/dist/colors.scss';

.select-dropdown {
  .selector-container.multiple.remove-button .selector-values > li {
    text-shadow: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    box-shadow: none;
    border-color: transparent;
    background-color: $main-1;
    width: auto;
    position: relative;

    .selector-helper {
      border-left-color: transparent;
      margin-right: 5px;
      width: 1em;
      &:hover {
        background-color: transparent;
      }
    }

    .selector-icon {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: white;
      height: 1em;
      width: 1em;
      top: 50%;
      margin-top: -0.5em;

      &:after {
        line-height: 1em;
        color: $main-1;
      }
    }
  }
}
