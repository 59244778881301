.capitalize-first-letter:first-letter {
    text-transform: uppercase;
}

.text-ada-grey {
    color: $grey-text-ada;
}

.text-grey {
    color: $grey-text;
}

.text-dark-grey {
    color: $dark-grey;
}

.text-white {
    color: #fff;
}

.text-black {
    color: #000;
}

.text-link {
    color: $link-color;
}

.text-warning {
    color: $text-warning;
}

.text-light-green {
    color: $light-green;
}

.text-confirmed-misspelling,
.text-potential-misspelling,
.text-neutral-misspelling {
    font-size: 1em;
}

.text-confirmed-misspelling {
    color: $confirmed-misspelling-color;
}

.text-potential-misspelling {
    color: $potential-misspelling-color;
}

.text-neutral-misspelling {
    color: $grey-text;
}

.text-potential-misspelling-color {
    color: $potential-misspelling-color;
}

.text-confirmed-misspelling-color {
    color: $confirmed-misspelling-color;
}

.text-gray-small {
    color: $text-gray-small;
}

.text-v-middle {
    vertical-align: middle !important;
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.inline {
    display:inline;
}

.inline-block {
    display: inline-block;
}

.inline-important {
    display:inline !important;
}

.inline-block-important {
    display:inline-block !important;
}

.block {
    display: block;
}

.word-break-all {
    word-break: break-all;
}
