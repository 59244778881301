$btn-secondary-border: #e4e4e4;

.button-monsido-icon {
  border: 0;
  background: transparent;
  color: #474747;
  margin: 1px;
  display: inline-block;
  clear: both;

  &:hover {
    margin: 0;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
  }
}

.btn {
  &:active, &.active {
    box-shadow: none;
  }
}

.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

