@import 'variables.scss';

.accordion {
    h2.accordion-header.card-header {
        background-color: #fff;
        padding: 0 !important;

        button.accordion-button {
            background-color: transparent;
            font-size: 21px;
            font-weight: 300;

            &:not(.collapsed) {
                color: $secondary-4;
            }
        }
    }
}