/* You can add global styles to this file, and also import other style files */
@import '@monsido/colors/dist/colors.scss';

.modal-content {
  transform: translateY(25%);
}

.modal-open .modal {
  opacity: 1;
}

.modal-backdrop.fade {
  opacity: 0.5 !important;
}

input.ng-invalid.ng-touched {
  border: 1px solid $secondary-9;
}
