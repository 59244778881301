.tooltip.show {
    opacity: 1;
}

[uib-tooltip-popup] {
  position: absolute;
  z-index: 100000;
  display: block;
  visibility: visible;
  font-size: 11px;
  line-height: 1.4;
  opacity: 0;

  &.in {
      opacity: 1
  }
}

.tooltip.no-limit {
    .tooltip-inner {
        --bs-tooltip-max-width: none;
    }
}