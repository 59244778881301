$fa-font-path: "../fonts/";

.fa-sm {
  font-size: 0.63em;
}

.font-size-1-75 {
  font-size: 1.75em;
}

.font-size-1-5 {
  font-size: 1.5em;
}

.font-size-1-3 {
  font-size: 1.3em;
}

.font-size-1-1 {
  font-size: 1.1em;
}

.font-size-0-9 {
  font-size: 0.9em;
}

.font-size-0-7 {
  font-size: 0.7em;
}

.font-size-0-6 {
  font-size: 0.6em;
}

.font-size-0-54 {
  font-size: 0.54em;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}
